import classnames from 'classnames';
import debounce from 'lodash/debounce';
import * as React from 'react';
import {
  FITMENT_SELECTOR_STORAGE_KEY,
  OPTIONAL_QUALIFIERS_STORAGE_KEY,
  OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
  DYN_SELECTED_VQ,
  DYN_SELECTED_VQ_REALID,
} from '../../utils/constants';
import { validateSelectedValues } from '../../utils/fitmentUtils';
import { escapeRegExp } from '../../utils/format';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from './models';
import NativeSelect from './NativeSelect';
import styles from './styles/fitmentSelector.scss';
import { getInitialQualifiers } from './utils';
const fuzzySearchLabel = (toMatch: string, labelsData) => {
  const toMatchEscaped = escapeRegExp(toMatch);
  const matched = labelsData?.find((v: { name: string }) =>
    v.name.match(new RegExp(`^${toMatchEscaped}$`, 'i'))
  );
  if (matched) {
    return matched.name;
  }
  return '';
};

const Labels = ({
  labels,
  CustomSelect,
  currentSelectedValues,
  onLabelChange,
  labelsData,
  styled,
  isVertical,
  isOptionalLabel,
}) => {
  // eslint-disable-next-line complexity
  return labels.map((label, index) => {
    let disabled = true;
    if (isOptionalLabel) {
      disabled = false;
    } else {
      disabled =
        (index > 0 && !currentSelectedValues[labels[index - 1].name]) ||
        !labelsData[label.name]?.length;
    }

    return CustomSelect ? (
      <CustomSelect
        key={label.name}
        value={
          fuzzySearchLabel(
            currentSelectedValues[label.name],
            labelsData[label.name]
          ) || ''
        }
        onChange={(e) => onLabelChange(label.name, e)}
        options={labelsData[label.name]}
        label={label}
      />
    ) : (
      <NativeSelect
        key={label.name}
        onChange={(e) => onLabelChange(label.name, e.currentTarget.value)}
        value={
          fuzzySearchLabel(
            currentSelectedValues[label.name],
            labelsData[label.name]
          ) || ''
        }
        className="Sui-FitmentSelector--select"
        disabled={disabled}
        name={label.customName || label.name}
        options={
          labelsData[label.name]?.map((item) => ({
            id: item.id,
            name: item.name,
          })) || []
        }
        styled={styled}
        isVertical={isVertical}
      />
    );
  });
};

export const OptionalLabels = ({
  labels,
  CustomSelect,
  currentSelectedValues,
  onLabelChange,
  labelsData,
  styled,
  isVertical,
  isOptionalLabel,
  showFieldLabels,
}) => {
  // eslint-disable-next-line complexity
  return labels.map((label, index) => {
    let disabled = true;
    if (isOptionalLabel) {
      disabled = false;
    } else {
      disabled =
        (index > 0 && !currentSelectedValues[labels[index - 1].name]) ||
        !labelsData[label.name]?.length;
    }

    return CustomSelect ? (
      <CustomSelect
        key={label.name}
        value={
          fuzzySearchLabel(
            currentSelectedValues[label.name],
            labelsData[label.name]
          ) || ''
        }
        onChange={(e) => onLabelChange(label.name, e)}
        options={labelsData[label.name]}
        label={label}
      />
    ) : (
      <NativeSelect
        key={label.name}
        onChange={(e) =>
          onLabelChange(label.name, e.currentTarget.value, label.real_id)
        }
        optionalLabelReference={label.real_id}
        value={
          fuzzySearchLabel(
            currentSelectedValues[label.name],
            labelsData[label.name]
          ) || ''
        }
        className="Sui-FitmentSelector--select"
        disabled={disabled}
        name={label.customName || label.name}
        options={
          labelsData[label.name]?.map((item) => ({
            id: item.id,
            name: item.name,
          })) || []
        }
        styled={styled}
        isVertical={isVertical}
        labelName={showFieldLabels && label.name}
      />
    );
  });
};

// eslint-disable-next-line complexity
const FitmentSelector = ({
  autocommit,
  autocommitDelay = 2000,
  className = '',
  clearButtonText = 'Clear',
  components,
  id,
  labels,
  labelsData,
  orientation = 'horizontal',
  onChange,
  onSubmit,
  onClearCb,
  optionalLabels = [],
  optionalLabelsData,
  onOptionalLabelsChange,
  optionalLabelsTitle = 'Optional Fields',
  searchButtonText = 'Search',
  selectedValues,
  styled = false,
  isLoadingOptionalLabel = false,
  showFieldLabels = true,
}: FitmentSelectorProps) => {
  const queryString = new URLSearchParams(window.location.search);
  const localStorageOptionalQualifiers = getInitialQualifiers(
    queryString,
    true
  );
  const localStorageOptionalRealIdQualifiers =
    JSON.parse(
      localStorage.getItem(OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY) || '{}'
    )?.[0] || {};

  const [internalSelectedValues, setInternalSelectedValues] = React.useState(
    selectedValues
  );
  const [
    internalOptSelectedValues,
    setInternalOptSelectedValues,
  ] = React.useState(localStorageOptionalQualifiers);

  const [
    internalOptRealIdSelectedValues,
    setInternalOptRealIdSelectedValues,
  ] = React.useState(localStorageOptionalRealIdQualifiers);

  const labelsContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (
      Object.keys(selectedValues || {}).length === labels.length &&
      labels.length > 1
    ) {
      updateLocalStorage(getDataToStore(selectedValues, labels));
    }
  }, [labels]);

  React.useEffect(() => {
    const allLabels = {
      ...labelsData,
      // ...optionalLabelsData,
    };
    const validSelectedValues = validateSelectedValues(
      selectedValues,
      allLabels,
      [...labels /* , ...optionalLabels */]
    );
    setInternalSelectedValues(validSelectedValues);
  }, [selectedValues, labelsData]);

  React.useEffect(() => {
    setInternalOptSelectedValues(localStorageOptionalQualifiers);
    setInternalOptRealIdSelectedValues(localStorageOptionalRealIdQualifiers);
  }, [optionalLabelsData]);

  React.useEffect(() => {
    const selectedvq =
      JSON.parse(localStorage.getItem(DYN_SELECTED_VQ) || '{}') || {};
    const selectedvqRealid =
      JSON.parse(localStorage.getItem(DYN_SELECTED_VQ_REALID) || '{}') || {};
    if (Object.keys(selectedvq).length) {
      setInternalOptSelectedValues(selectedvq);
      setInternalOptRealIdSelectedValues(selectedvqRealid);
    } else {
      setInternalOptSelectedValues({});
      setInternalOptRealIdSelectedValues({});
    }
  }, [isLoadingOptionalLabel]);

  React.useEffect(() => {
    if (optionalLabels.length > 0) {
      setTimeout(() => {
        const className = getNextClassNameOptLabel();
        const elementToView = document.querySelector(
          `.Sui-FitmentSelector--select-wrapper-${className}`
        ) as HTMLElement;
        const selectElementToFocus = document.querySelector(
          `.Sui-FitmentSelector--select-wrapper-${className} select`
        ) as HTMLElement;
        if (elementToView) {
          elementToView.scrollIntoView();
          selectElementToFocus.focus();
        }
      }, 200);
    }
  }, [optionalLabels]);

  const getNextClassNameOptLabel = () => {
    const idx = JSON.parse(localStorage.getItem('nextOptLabelIdx'));
    const label = optionalLabels[idx];

    return label.real_id;
  };

  /**
   * Function to set the value for each label when user changes its value.
   * This function also clears the state of selected labels when a parent label changes
   * Example:
   * Year > Make > Model
   * If all selects have values and user selects a different Year; Make and Model selects must be reseted
   */
  const onLabelChange = async (
    labelId: number | string,
    value: string | number
  ) => {
    const allLabels = [...labels /* , ...optionalLabels */];
    const nextLabelIndex =
      allLabels.findIndex((item) => item.name === labelId) + 1;
    const labelToClearData = allLabels.slice(nextLabelIndex);
    const newSelectedValues = { ...internalSelectedValues, [labelId]: value };

    for (const key of labelToClearData) {
      delete newSelectedValues[key.name];
    }
    // If there is change we cancel the previous debounced function execution to prevent autocommit
    debouncedOnChange.cancel();

    setInternalSelectedValues(newSelectedValues);
    onChange?.(labelId, newSelectedValues);
    if (
      autocommit &&
      Object.keys(newSelectedValues).length ===
        labels.length /* + optionalLabels.length */
    ) {
      debouncedOnChange(newSelectedValues);
    }
  };

  const onOptionalLabelChange = async (
    labelId: number | string,
    value: string | number,
    realId: string | number
  ) => {
    const newSelectedValues = {
      ...internalOptSelectedValues,
      [labelId]: value,
    };

    const newRealIdSelectedValues = {
      ...internalOptRealIdSelectedValues,
      [realId]: value,
    };

    const nextOptLabelIdx = optionalLabels.findIndex(
      (v) => v.real_id === realId
    );
    localStorage.setItem('nextOptLabelIdx', `${nextOptLabelIdx + 1}`);

    if (value === '') {
      delete newRealIdSelectedValues[realId];
      delete newSelectedValues[labelId];
    }
    // remove all the selected real_id values that are not on the
    // optional labels
    const optRealIds = optionalLabels.map((v) => {
      return v.real_id;
    });

    Object.keys(newRealIdSelectedValues).forEach((v) => {
      if (!optRealIds.includes(v)) {
        delete newRealIdSelectedValues[v];
      }
    });
    // remove all the selected values that are not on the
    // optional labels
    const optNames = optionalLabels.map((v) => {
      return v.name;
    });

    Object.keys(newSelectedValues).forEach((v) => {
      if (!optNames.includes(v)) {
        delete newSelectedValues[v];
      }
    });

    // If there is change we cancel the previous debounced function execution to prevent autocommit
    debouncedOnChange.cancel();

    setInternalOptSelectedValues(newSelectedValues);
    setInternalOptRealIdSelectedValues(newRealIdSelectedValues);
    onOptionalLabelsChange?.(
      labelId,
      realId,
      newSelectedValues,
      newRealIdSelectedValues
    );
    if (
      autocommit &&
      Object.keys(newSelectedValues).length === optionalLabels.length
    ) {
      debouncedOnChange(newSelectedValues);
    }
  };

  const debouncedOnChange = React.useCallback(
    debounce(async (values) => {
      submit(values, internalOptRealIdSelectedValues);
    }, autocommitDelay),
    [autocommitDelay, labels]
  );

  const onSearch = () => {
    submit(internalSelectedValues, internalOptRealIdSelectedValues);
  };

  const updateLocalStorage = (data: string, optValues?: SelectedValues) => {
    if (optValues) {
      localStorage.setItem(
        OPTIONAL_QUALIFIERS_STORAGE_KEY,
        JSON.stringify([internalOptSelectedValues])
      );
      localStorage.setItem(
        OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
        JSON.stringify([internalOptRealIdSelectedValues])
      );
    } else {
      localStorage.setItem(
        FITMENT_SELECTOR_STORAGE_KEY,
        JSON.stringify([data])
      );
    }
  };

  const submit = (values: SelectedValues, optValues?: SelectedValues) => {
    // Keep this function because on the future we may want to have multiple fitment in the localStorage
    // const storedFitments: FitmentSelectorStore[] = JSON.parse(
    //   localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '[]'
    // );
    updateLocalStorage(getDataToStore(values, labels));
    updateLocalStorage('', optValues);
    localStorage.setItem(DYN_SELECTED_VQ, '{}');
    localStorage.setItem(DYN_SELECTED_VQ_REALID, '{}');

    // Keep this function because on the future we may want to have multiple fitment in the localStorage
    // if (!fitmentExistsInLocalStorage(newFitmentToStore, storedFitments)) {
    //   localStorage.setItem(
    //     FITMENT_SELECTOR_STORAGE_KEY,
    //     JSON.stringify([
    //       newFitmentToStore,
    //       ...storedFitments.slice(0, LIMIT_FITMENTS_LOCAL_STORAGE),
    //     ])
    //   );
    // }
    onSubmit?.(values, optValues);
  };

  const onClear = () => {
    onChange?.(null, null);
    onClearCb?.();
    setInternalSelectedValues(null);
    localStorage.removeItem(FITMENT_SELECTOR_STORAGE_KEY);
    localStorage.removeItem(OPTIONAL_QUALIFIERS_STORAGE_KEY);
    localStorage.removeItem(OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY);
    localStorage.removeItem(DYN_SELECTED_VQ);
    localStorage.removeItem(DYN_SELECTED_VQ_REALID);
  };

  const isVertical = orientation === 'vertical';
  const CustomSelect = components?.select;
  const currentSelectedValues = internalSelectedValues || {};
  const currentOptSelectedValues = internalOptSelectedValues || {};

  if (!labels.length) {
    return null;
  }

  return (
    <div
      id={id}
      ref={labelsContainerRef}
      className={classnames(
        styles.root,
        {
          [styles.vertical]: isVertical,
        },
        className
      )}
    >
      <Labels
        labels={labels}
        CustomSelect={CustomSelect}
        currentSelectedValues={currentSelectedValues}
        onLabelChange={onLabelChange}
        labelsData={labelsData}
        styled={styled}
        isVertical={isVertical}
        isOptionalLabel={false}
      />
      {isLoadingOptionalLabel && 'Loading optional fields...'}
      {Object.keys(internalSelectedValues || {}).length >= labels.length &&
      optionalLabels.length ? (
        <>
          <p className="Sui-FitmentSelector--optional-fields-label">
            {optionalLabelsTitle}
          </p>
          <OptionalLabels
            labels={optionalLabels}
            CustomSelect={CustomSelect}
            currentSelectedValues={currentOptSelectedValues}
            onLabelChange={onOptionalLabelChange}
            labelsData={optionalLabelsData}
            styled={styled}
            isVertical={isVertical}
            isOptionalLabel
            showFieldLabels={showFieldLabels}
          />
        </>
      ) : null}
      {!autocommit ? (
        <div
          className={classnames(
            styles.actions,
            {
              [styles.actionsVertical]: styled && isVertical,
            },
            'Sui-FitmentSelector--actions'
          )}
        >
          <button
            type="button"
            className={classnames(
              {
                [`SuiButton primary ${styles.styledSearchBtn}`]: styled,
                [styles.styledSearchBtnVertical]: styled && isVertical,
              },
              'Sui-FitmentSelector--searchBtn'
            )}
            disabled={Object.keys(currentSelectedValues).length < labels.length}
            onClick={onSearch}
          >
            {searchButtonText}
          </button>
          <button
            type="button"
            className={classnames(
              {
                [`SuiButton secondary ${styles.styledClearBtn}`]: styled,
              },
              'Sui-FitmentSelector--clearBtn'
            )}
            disabled={Object.keys(currentSelectedValues).length === 0}
            onClick={onClear}
          >
            {clearButtonText}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default FitmentSelector;

/**
 * Function to get data to store in LocalStorage.
 * This data will be used in FitmentStore component
 * @returns
 * [
 *  { "label": labelItemData, "value": labelSelectedValue },
 * ]
 */
function getDataToStore(
  selectedValues: SelectedValues,
  labels: FitmentLabelEntity[]
) {
  return labels
    .reduce((acc, label) => {
      if (selectedValues[label.name]) {
        return [...acc, selectedValues[label.name]];
      }
      return acc;
    }, [])
    .join('|');
}

// Keep this function because on the future we may want to have multiple fitment in the localStorage
// function fitmentExistsInLocalStorage(newFitmentToStore, storedFitments) {
//   const fitToStoreIds = getFitmentValuesIds(newFitmentToStore);
//   return !!storedFitments.find(
//     (storedFitment) => fitToStoreIds === getFitmentValuesIds(storedFitment)
//   );
// }
// function getFitmentValuesIds(fitmentStore) {
//   return fitmentStore.reduce((acc, item) => `${acc}-${item.value.id}`, '');
// }
