import { FACET_PREFIX, SearchParams, SelectedFacetsType } from '.';
import { Orientation } from '../../models/generic';
import {
  FITMENT_SELECTOR_STORAGE_KEY,
  OPTIONAL_QUALIFIERS_STORAGE_KEY,
} from '../../utils/constants';
import { FacetFilterOnChange } from '../FacetFilter/models';
import { getInitialQualifiersForURL } from '../FitmentSelector/utils';
import { FitmentSelectorWrapperProps } from '../FitmentSelectorWrapper/models';
import { ProductListResponse } from '../ProductListWrapper/models';

const aqUrlPattern = new RegExp('^vq\\[([a-z0-9_\\(\\)]+)\\]$');

export function parseResponse(
  data: ProductListResponse['facets'],
  collapsed?: boolean
) {
  const response = (data || []).reduce((acc, item, index) => {
    return {
      ...acc,
      [`${item.name}`]: {
        order: index,
        ...(collapsed ? { collapsed: true } : {}),
        title: item.name,
        values: item.values,
      },
    };
  }, {});
  return response;
}

export function parseSelectedFacets(
  data: FacetFilterOnChange
): {
  [key: string]: string[] | number[];
} {
  return Object.keys(data.selectedValues || {}).reduce((acc, item) => {
    return { ...acc, [item]: data.selectedValues?.[item] };
  }, {});
}

export function getOritentation(queryString: URLSearchParams): Orientation {
  const orientation = queryString.get('orientation') as Orientation;
  if (orientation === 'vertical') {
    return orientation;
  }
  return 'horizontal';
}

export function updateUrl<T>(
  key: T extends string ? string : keyof SearchParams,
  value?: Array<string | number> | string | number
) {
  const params = new URLSearchParams(window.location.search);
  if (Array.isArray(value)) {
    params.delete(key);
    for (const val of value) {
      params.append(key, String(val));
    }
  } else if (value) {
    params.set(key, String(value));
  } else {
    params.delete(key);
  }

  const newUrl = `${window.location.origin}${
    window.location.pathname
  }?${params.toString()}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
}

export function getInitialFitment(queryString: URLSearchParams): string {
  return (
    queryString.get('fitment') ||
    JSON.parse(
      localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '{}'
    )?.[0] ||
    ''
  );
}

export function getInitialVq() {
  return (
    JSON.parse(
      localStorage.getItem(OPTIONAL_QUALIFIERS_STORAGE_KEY) || '{}'
    )?.[0] || {}
  );
}

export function getProductQuery(
  selectedFacets: SelectedFacetsType,
  selectedFitmentQuery: string,
  groupId?: FitmentSelectorWrapperProps['groupId'],
  queryString?: URLSearchParams
) {
  return {
    ...selectedFacets,
    // ...parseVehicleQualifierQuery(queryString),
    ...getInitialQualifiersForURL(queryString),
    ...(groupId ? { fitment: selectedFitmentQuery } : {}),
  };
}

export function getSearchParams(queryString: URLSearchParams): SearchParams {
  return {
    fitment: getInitialFitment(queryString),
    limit: Number(queryString.get('limit')) || 20,
    orientation: getOritentation(queryString),
    page: Number(queryString.get('page')) || 1,
    q: queryString.get('q') || '',
    sort: queryString.get('sort') || '',
    vq: queryString.get('vq') || '',
  };
}

export function isFacetEqual(
  facetA: SelectedFacetsType,
  facetB: SelectedFacetsType
) {
  if (Object.keys(facetA).length !== Object.keys(facetB).length) {
    return false;
  }

  const facetKeys = Object.keys(facetA);
  for (const key of facetKeys) {
    const valuesFacetA = facetA[key];
    const valuesFacetB = facetB[key];
    for (const [index, value] of Object.entries(valuesFacetA)) {
      if (value !== valuesFacetB[Number(index)]) {
        return false;
      }
    }
  }
  return true;
}

export function getFacetKeysFromUrl(queryString: URLSearchParams) {
  const fitmentQuery = [];
  for (const key of Array.from(queryString.keys())) {
    if (key.includes(FACET_PREFIX)) {
      fitmentQuery.push(key);
    }
  }
  return fitmentQuery;
}

export function transformOptionalLabelsAndData(vehicleQualifiers) {
  const optionalData = {};
  vehicleQualifiers?.forEach(
    (vq: { id: string | number; name: string | number; values: [] }) => {
      optionalData[vq.name] = vq.values;
    }
  );
  const labels = vehicleQualifiers?.map(
    (vq: {
      id: string | number;
      name: string | number;
      real_id: string | number;
      values: [];
    }) => {
      return {
        id: vq.name,
        name: vq.name,
        real_id: vq.id,
      };
    }
  );

  return [labels, optionalData];
}

export function parseVehicleQualifierQuery(queryString: URLSearchParams) {
  const vqs = {};
  queryString.forEach((value, key) => {
    // match only 'vq[' +  :alphanum: with _ + ']'
    if (key.match(aqUrlPattern)) {
      vqs[key] = value;
    }
  });
  return vqs;
}

export function qualifierQueryToTitleCase(obj, labels = []) {
  const newObj = {};
  Object.keys(obj).forEach((v) => {
    const bracketStripped = v.match(aqUrlPattern)?.[1];
    const foundLabel = labels.find((l) => l?.real_id === bracketStripped);
    const key = foundLabel?.name;

    newObj[key] = obj[v];
  });

  return newObj;
}

export function qualifierQueryToRealId(obj) {
  const newObj = {};
  Object.keys(obj).forEach((v) => {
    const bracketStripped = v.match(aqUrlPattern)?.[1];
    const key = bracketStripped;

    newObj[key] = obj[v];
  });

  return newObj;
}

export function snakeCaseToTitleCase(snakeCaseStr) {
  return snakeCaseStr
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
}

export function isEqual(obj1, obj2) {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    (Object.keys(obj1) as (keyof typeof obj1)[]).every((key) => {
      return (
        Object.prototype.hasOwnProperty.call(obj2, key) &&
        obj1[key] === obj2[key]
      );
    })
  );
}
