import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../components/FitmentSelector/models';

function validateSelectedValues(
  selectedValues: SelectedValues,
  labelsData: FitmentSelectorProps['labelsData'],
  labels: FitmentLabelEntity[]
) {
  let validSelectedValues: SelectedValues = {};

  for (const label of labels) {
    const labelId = (selectedValues || {})[label.name];
    if (!labelId || !labelsData[label.name]) break;
    const selectedValueExists = labelsData[label.name].find(
      (item) => String(item.id).toLowerCase() === String(labelId).toLowerCase()
    );
    if (!selectedValueExists) {
      break;
    }
    validSelectedValues = {
      ...validSelectedValues,
      [label.name]: selectedValues[label.name],
    };
  }

  return validSelectedValues;
}

export { validateSelectedValues };

export function convertSelectedDataToArray(
  labels: FitmentLabelEntity[],
  values: SelectedValues,
  labelValues: FitmentSelectorProps['labelsData']
) {
  const parsedValues = [];
  for (const label of labels) {
    if (values[label.name] && labelValues[label.name]) {
      const selectedValueName = labelValues[label.name].find(
        (item) =>
          String(item.id).toLowerCase() ===
          String(values[label.name]).toLowerCase()
      )?.name;

      parsedValues.push({
        key: label.name,
        value: selectedValueName as string | number,
      });
    }
  }

  return parsedValues;
}
