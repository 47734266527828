import classnames from 'classnames';
import * as React from 'react';
import List from '../../common/List/List';
import ProductListItem from '../../common/ProductListItem/ProductListItem';
import { ProductListProps } from './models';
import styles from './styles/productList.scss';

export const DEFAULT_SORT_OPTIONS = [
  { label: 'Recommended', value: '' },
  { label: 'Price: Low to High', value: 'price:asc' },
  { label: 'Price: High to Low', value: 'price:desc' },
];

const ListItems = ({
  renderList,
  data,
  styled,
  orientation,
  visibleFields,
  noImageUrl,
  linkEl,
  onItemClick,
  producListMetaData,
}) => {
  return renderList
    ? renderList(data, producListMetaData)
    : data.map((item) => {
        return (
          <ProductListItem
            key={item.id}
            className={classnames({
              [styles.resultItemStyledInGrid]:
                styled && orientation === 'vertical',
            })}
            data={item}
            styled={styled}
            orientation={orientation}
            visibleFields={visibleFields}
            noImageUrl={noImageUrl}
            linkEl={linkEl}
            onItemClick={onItemClick}
          />
        );
      });
};

const ProductList = ({
  list,
  totalPages = 1,
  currentPage = 1,
  onPageChanged = () => null,
  className = '',
  styled,
  orientation = 'horizontal',
  showPagination = false,
  showLoadingIndicator = false,
  visibleFields,
  renderList,
  renderNoResults,
  isLoading,
  noImageUrl,
  linkEl,
  showControls,
  itemsPerPage,
  onItemsPerPageChange,
  onLayoutChange,
  onItemClick,
  onSortChange,
  sortOptions,
  selectedSort,
  producListMetaData,
}: ProductListProps) => {
  const Link = linkEl || 'a';
  const [itemsPerPageInternal, setItemsPerPage] = React.useState(itemsPerPage);
  const [orientationInternal, setOrientation] = React.useState(orientation);
  const [selectedSortInternal, setSelectedSortInternal] = React.useState(
    selectedSort
  );

  React.useEffect(() => {
    setOrientation(orientation);
  }, [orientation]);

  React.useEffect(() => {
    setItemsPerPage(itemsPerPage);
  }, [itemsPerPage]);

  React.useEffect(() => {
    setSelectedSortInternal(selectedSort);
  }, [selectedSort]);

  return (
    <List
      list={list}
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChanged={onPageChanged}
      className={classnames(
        { [styles.styledListContainer]: styled },
        className
      )}
      orientation={orientationInternal}
      showPagination={showPagination}
      showLoadingIndicator={showLoadingIndicator}
      renderNoResults={renderNoResults}
      isLoading={isLoading}
      showControls={showControls}
      itemsPerPage={itemsPerPageInternal}
      onItemsPerPageChange={(value) => {
        onItemsPerPageChange?.(value);
        setItemsPerPage(value);
      }}
      onLayoutChange={(value) => {
        setOrientation(value);
        onLayoutChange?.(value);
      }}
      styled={styled}
      sortOptions={sortOptions || DEFAULT_SORT_OPTIONS}
      onSortChange={(value) => {
        onSortChange?.(value);
        setSelectedSortInternal(value);
      }}
      selectedSort={selectedSortInternal}
    >
      <ListItems
        renderList={renderList}
        data={list}
        styled={styled}
        orientation={orientationInternal}
        visibleFields={visibleFields}
        noImageUrl={noImageUrl}
        linkEl={Link}
        onItemClick={onItemClick}
        producListMetaData={producListMetaData}
      />
    </List>
  );
};

export default ProductList;
