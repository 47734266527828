import classnames from 'classnames';
import * as React from 'react';
import { FACET_PREFIX, SelectedFitmentValues } from '.';
import Modal from '../../common/Modal/Modal';
import { convertSelectedDataToArray } from '../../utils/fitmentUtils';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../FitmentSelector/models';
import FitmentSelectorWrapper from '../FitmentSelectorWrapper';
import { FitmentSelectorWrapperProps } from '../FitmentSelectorWrapper/models';
import styles from './fitmentSelectorModal.module.scss';
import { SearchPageContext } from './SearchPageProvider';
import { isEqual, parseVehicleQualifierQuery, updateUrl } from './utils';
import { getConfig } from '../../config';
interface FitmentSelectorModalProps {
  classes: { root: string };
  isModalOpen: boolean;
  disbaleAllHtmlEl: boolean;
  showModal: (value: boolean) => void;
  onSubmit?: (
    values: SelectedFitmentValues,
    changed: boolean,
    optValues?: SelectedValues
  ) => void;
  groupId?: FitmentSelectorWrapperProps['groupId'];
  fitmentRuleId?: FitmentSelectorWrapperProps['fitmentRuleId'];
  /**
   * Pipe delimited Fitment
   * Example: 2020|Chevrolet|Colorado
   */
  selectedFitment?: string;
}

const FitmentSelectorModal = ({
  classes,
  disbaleAllHtmlEl,
  isModalOpen,
  showModal,
  onSubmit,
  groupId,
  fitmentRuleId,
  selectedFitment: initialSelectedFitment,
}: FitmentSelectorModalProps) => {
  const {
    clearFacetsOnSaveState,
    facetKeysState,
    selectedFacetState,
    optionalLabelsDataState,
    optionalLabelsState,
    qualifiersStatusState,
  } = React.useContext(SearchPageContext);
  const [facetKeys] = facetKeysState;
  const [selectedFacets, setSelectedFacets] = selectedFacetState;
  const [clearFacetsOnSave] = clearFacetsOnSaveState;
  const [optionalLabelsData] = optionalLabelsDataState;
  const [optionalLabels] = optionalLabelsState;
  const [qualifiersStatus] = qualifiersStatusState;

  const [fitmentLabels, setFitmentLabels] = React.useState<
    FitmentLabelEntity[]
  >([]);
  const [fitmentValues, setFitmentValues] = React.useState<
    FitmentSelectorProps['labelsData']
  >();
  const [
    selectedFitment,
    setSelectedFitment,
  ] = React.useState<SelectedValues>();
  const [fitmentKey, setFitmentKey] = React.useState('');
  const [isChanged, setIsChanged] = React.useState(false);
  const { FACET_CLEAR_WHITELIST } = getConfig();
  React.useEffect(() => {
    if (fitmentLabels.length && initialSelectedFitment) {
      const selectedFitmentValues = initialSelectedFitment.split('|');
      const selectedFitmentParsed = fitmentLabels.reduce(
        (acc, label, index) => {
          return {
            ...acc,
            [label.name]: selectedFitmentValues[index],
          };
        },
        {}
      );

      setSelectedFitment(selectedFitmentParsed);
      setFitmentKey(JSON.stringify(selectedFitmentParsed));
    }
  }, [fitmentLabels, initialSelectedFitment]);

  React.useEffect(() => {
    setIsChanged(false);
  }, [isModalOpen]);

  if (!groupId) {
    return null;
  }

  return (
    <Modal
      className={classnames(classes.root, styles.modal, {
        disableAll: disbaleAllHtmlEl,
      })}
      isOpen={isModalOpen}
      onClose={() => showModal(false)}
      showCloseButton={true}
    >
      <div className={styles.header}>
        <h1 className="Pl-Fitment-modal--title">Select your fitment</h1>
      </div>

      <div className={styles.modalBody} id="fitment-selector-modal-container">
        <FitmentSelectorWrapper
          /*
            Need to change the key when fitment labels
            or selected fitment changes to set initial values properly.
            This will re-mount the component
          */
          key={fitmentKey}
          onDataLoaded={(labels, _, data) => {
            if (labels && data) {
              setFitmentLabels(labels);
              setFitmentValues(data);
            }
          }}
          className={styles.firmentSelector}
          styled={false}
          orientation="vertical"
          searchButtonText="Save"
          onSubmit={(values, optValues) => {
            const fitmentIsNotChanged = isEqual(
              values || {},
              selectedFitment || {}
            );
            onSubmit?.(
              convertSelectedDataToArray(
                fitmentLabels,
                values,
                fitmentValues || {}
              ),
              isChanged,
              optValues
            );
            showModal(false);
            // clear facets if previous fitment selected is different on currently selected
            // and also check for the config `clearFacetsOnSave`
            if (
              clearFacetsOnSave &&
              !fitmentIsNotChanged /* <- equivalent to fitment has changed from previous to current */
            ) {
              setSelectedFacets({});
              facetKeys
                .filter((i) => {
                  // remove facets that are white listed via config
                  // it will not be removed on facet upon save
                  return !FACET_CLEAR_WHITELIST?.includes(i);
                })
                .forEach((item) => {
                  updateUrl<string>(`${FACET_PREFIX}${item}`, []);
                });
            }
          }}
          onClearCb={() => {
            // On clearing the fitments,
            // pre-selected facets should not be cleared

            const optValues = parseVehicleQualifierQuery(
              new URLSearchParams(window.location.search)
            );
            Object.keys(optValues || {}).forEach((vqKeys) => {
              updateUrl<string>(vqKeys, []);
            });
            if (clearFacetsOnSave) {
              setSelectedFacets({});
              facetKeys.forEach((item) =>
                updateUrl<string>(`${FACET_PREFIX}${item}`, [])
              );
            }
          }}
          onChange={(_, values) => {
            setIsChanged(true);
            if (!values) {
              onSubmit?.([], true, {});
            }
          }}
          groupId={groupId}
          fitmentRuleId={fitmentRuleId}
          selectedValues={selectedFitment}
          optionalLabelsData={optionalLabelsData}
          optionalLabels={optionalLabels}
          qualifiersStatus={qualifiersStatus}
          selectedFacets={selectedFacets}
        />
      </div>
    </Modal>
  );
};

export default FitmentSelectorModal;
