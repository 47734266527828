import { stringify } from 'querystring';
import { getConfig } from '../config';
import { GenericObject } from '../models/generic';

export interface IRestFactory {
  get: <T>(
    path: string,
    params?: GenericObject,
    signal?: AbortSignal
  ) => Promise<T>;
}

const restService = (): IRestFactory => ({
  async get<T>(
    path: string,
    params: GenericObject = {},
    signal?: AbortSignal
  ): Promise<T> {
    const { API_KEY, throttleRequests } = getConfig();
    if (!API_KEY) {
      const errorMsg = 'API_KEY is not valid. Please enter a valid one.';
      console.error(errorMsg);
    }
    // This try-catch is useful because fetch does not return error when response is not ok
    // so we want to catch any real error and bad responses.
    // eslint-disable-next-line no-useless-catch
    try {
      const clearedParams = JSON.parse(JSON.stringify(params));
      const query = Object.keys(clearedParams).length
        ? `?${stringify(clearedParams)}`
        : '';
      const pathWithQuery = `${path}${query}`;
      // To prevent issues with parallel requests using Jest, we don't cache them in test env.
      const cachedPromise =
        process.env.NODE_ENV !== 'test'
          ? throttleRequests[pathWithQuery]
          : null;
      const promise =
        cachedPromise ||
        fetch(pathWithQuery, {
          headers: { 'sunhammer-api-key': API_KEY },
          signal,
        });
      throttleRequests[pathWithQuery] = promise;
      const response = await throttleRequests[pathWithQuery];
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      delete throttleRequests[pathWithQuery];
      return response.clone().json();
    } catch (error) {
      throw error;
    }
  },
});

export default restService();
